
























































import {  AlunoTurma, Turma } from "@/core/models/geral";
import { PageBase } from "@/core/models/shared";
import { TurmaService } from "@/core/services/geral";
import {Component} from "vue-property-decorator";


@Component
export default class RelatorioChamada extends PageBase{
    service = new TurmaService();
    item: Turma = new Turma ();
    lista: AlunoTurma[] = [];
    filtro = {
        turmaId: 0
    }
    overlay: boolean = false;

    mounted() {

        this.filtro.turmaId = Number(this.$route.query.turmaId);

        this.overlay = true;

        this.service.ObterPorId(this.filtro.turmaId,"Alunos.Aluno, Local, Curso").then(
            res =>{
                this.item = res.data;
                this.lista = [];
                this.lista = this.item.alunos.sort((a,b) => 
                a.aluno.nomeCracha.localeCompare(b.aluno.nomeCracha));
                
                var i = 0;
                for(i = this.item.alunos.length; i < this.item.vagas; i++){
                    this.lista.push(new AlunoTurma);
                }
            },
            err => {
                if (!err.response){
                    this.$swal("Aviso", "Não foi possível acessar a API", "error");
                }
                else{
                    this.$swal("Aviso",err.response.data,"error")
                }
            }
        ).finally(() => {
            this.overlay = false;
        })
    }
}
